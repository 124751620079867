/**
 * Options that control the integration with theschoolbus.net for the currently running code.
 * 
 * See also styles/integration.scss
 */
export const isIntegratedInSchoolBus = false;
export let envormentType: 'dev' | 'qa' | 'test' | 'support' | 'preprod' | 'production' | 'integrated' | 'unknown' = 'unknown';
if (window.location.hostname.indexOf('schoolbus.co.uk') >= 0) {
    envormentType = 'production';
} else if (window.location.hostname.indexOf('theschoolbus-test.co.uk') >= 0) {
    envormentType = 'test';
} else if (window.location.hostname.indexOf('theschoolbus-support.co.uk') >= 0) {
    envormentType = 'support';
} else if (window.location.hostname.indexOf('theschoolbus-preprod.co.uk') >= 0) {
    envormentType = 'preprod';
} else if (window.location.hostname.indexOf('compliance-standard-qa.azurewebsites.net') >= 0) { // Made more specific to avoid a CNAME lookup backdoor.
    envormentType = 'qa';
} else if (window.location.hostname.indexOf('localhost') >= 0) {
    envormentType = 'dev';
}

export let integrationConfig = {
    /**
     * API server.
     */
    apiServer: '/',

    /**
     * Base URL to access what would normally be the / for static files served via ClientApp/public.
     */
    clientAppPublic: '/',

    /**
     * Allow local management of logins useful for developers etc. but when running integrated we don't want to allow them.
     */
    allowDeveloperLogin: false,

    /**
     * When defined this user is returned when the login rather than redirecting to the user login page.
     */
    useFallbackUserOnLoginFail: '',

    /**
     * URL to redirect to the main school bus.
     */
    schoolBusBaseUrl: '/',
};

switch (envormentType) {
    case 'dev':
        integrationConfig = {
            ...integrationConfig,

            allowDeveloperLogin: true,
            schoolBusBaseUrl: 'https://www.theschoolbus-test.co.uk/',
        };
        break;
    case 'qa':
        integrationConfig = {
            ...integrationConfig,

            allowDeveloperLogin: true,
            schoolBusBaseUrl: 'https://www.theschoolbus-test.co.uk/',
        };
        break;
    case 'test':
        integrationConfig = {
            ...integrationConfig,

            allowDeveloperLogin: false,
            schoolBusBaseUrl: 'https://www.theschoolbus-test.co.uk/',
        };
        break;
    case 'support':
        integrationConfig = {
            ...integrationConfig,

            allowDeveloperLogin: false,
            schoolBusBaseUrl: 'https://www.theschoolbus-support.co.uk/',
        };
        break;
    case 'preprod':
        integrationConfig = {
            ...integrationConfig,

            allowDeveloperLogin: false,
            schoolBusBaseUrl: 'https://www.theschoolbus-preprod.co.uk/',
        };
        break;
    case 'production':
        integrationConfig = {
            ...integrationConfig,

            allowDeveloperLogin: false,
            schoolBusBaseUrl: 'https://www.schoolbus.co.uk/',
        };
        break;
    //case 'integrated':
    //    integrationConfig = {
    //        ...integrationConfig,

    //        apiServer: 'http://test-monitoringreporting/',
    //        clientAppPublic: '/MonitoringReportingStatic/',
    //        allowDeveloperLogin: false,
    //        fullLayoutControl: false,
    //        useServiceWorker: false,
    //        useSidebar: false,
    //    };
    //    break;
}
